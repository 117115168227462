import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'warningCircle',
    name: 'Warning Circle',
    category: 'misc',
    path: (
      <path
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm1.5-18.196L12.713 15h-1.426L10.5 5.804V4h3v1.804zM13.415 17v3h-2.83v-3h2.83z"
        fill="currentColor"
        fillRule="evenodd"
      />
    ),
    viewBox: '0 0 24 24',
  }
}

export const IconWarningCircle = makeIcon(def())
export default IconWarningCircle
